import {QuoteList} from "@/services/boostpack/quote/quote_list.type";
import {Quote} from "@/services/boostpack/quote/quote.type";

/**
 *
 */
export class QuoteFactory {

	/* eslint-disable */

	/**
	 *
	 * @param quoteData
	 */
	createQuoteListByResponse(quoteData: Record<string, any>): QuoteList{
		const quoteList = new QuoteList();

		for (const [key, item] of Object.entries(quoteData)) {
			quoteList.push(this.createQuoteByResponse(item));
		}

		return quoteList;
	}

	createQuoteListWithMetaData(quoteResponse: Record<string, any>): QuoteList {
		const quoteData = quoteResponse.data;
		const metaData = quoteResponse.meta;
		let quoteList = this.createQuoteListByResponse(quoteData)
		quoteList.enhanceMetaData(metaData);

		return quoteList;
	}

	/**
	 *
	 * @param item
	 */
	createQuoteByResponse(item: Record<string, any>): Quote{
		const id = item.id;
		const uuid = item.uuid;
		const type = item.type;
		const state = item.state;
		const language = item.language;
		const quote = item.quote;
		const author = item.author;
		const birthDate = item.language;
		const deathDate = item.deathDate;
		const publication = item.publication;
		const tags = item.tags;
		const createdAt = item.created_at;
		const updatedAt = item.updated_at;
		const reference = item.reference;
		const parent_id = item.parent_id;
		const data = item.data;
		return new Quote(id, uuid, type, state, language, quote, author, birthDate, deathDate, publication, tags, createdAt, updatedAt, reference, parent_id, data);
	}
}