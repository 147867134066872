import {Boostpack} from "@/services/boostpack/boostpack.type";
import {PaginationMetaInterface} from "@/services/boostpack/core/pagination_meta.interface";
import {Quote} from "@/services/boostpack/quote/quote.type";

export class QuoteList extends Array<Quote> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Quote> = []) {
		super();
		this.push(...items);
		Object.setPrototypeOf(this, QuoteList.prototype);
	}

	add(items: Quote) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Quote {
		return this[index];
	}

	getItemById(quoteId: number): Quote | undefined {
		return this.find( ({ id }) => id === quoteId);
	}

	getItemByUuid(quoteUuid: string): Quote | undefined {
		return this.find( ({ uuid }) => uuid === quoteUuid);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}