import {QuoteInterface} from "@/services/boostpack/quote/quote.interface";

export class Quote implements QuoteInterface {

	id: number;
	uuid: string;
	type: string;
	state: string;
	language: string;
	quote: string;
	author: string;
	birthDate: Date;
	deathDate: Date;
	publication: Date;
	tags: any;
	// userId: number;
	// user: UserInterface;
	createdAt: Date;
	updatedAt: Date;
	reference?: string|null;
	parentId?: number|null;
	data?: any;

	constructor(id: number, uuid: string, type: string, state: string, language: string, quote: string, author: string, birthDate: Date, deathDate: Date, publication: Date, tags: any, createdAt: Date, updatedAt: Date, reference: string|null = null, parentId: number|null = null, data: any = null) {
		this.id = id;
		this.uuid = uuid;
		this.type = type;
		this.state = state;
		this.language = language;
		this.quote = quote;
		this.author = author;
		this.birthDate = birthDate;
		this.deathDate = deathDate;
		this.publication = publication;
		this.tags = tags;
		// userId: number;
		// user: UserInterface;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.reference = reference;
		this.parentId = parentId;
		this.data = data;
	}
}