import ApiService from "../base/api.service";
import {QuoteFactory} from "@/services/boostpack/quote/quote_factory.type";
import {Quote} from "@/services/boostpack/quote/quote.type";

const resource = '/quotes';

/* eslint-disable */
class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const QuoteService = {
	get() {
		try {
			return ApiService.get(`${resource}`);
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	getQuoteBySearch: async function (search: string, page: string|null = null, perPage: number|null = null) {
		try {
			let result: any = {};
			const params = new URLSearchParams([['search', search]]);
			if (page) {
				params.append('page', page);
			}
			if (perPage) {
				params.append('perPage', String(perPage));
			}
			const response = await ApiService.get(`${resource}`, {params});
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const quoteFactory = new QuoteFactory();
					result = quoteFactory.createQuoteListWithMetaData(response.data);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	async getBoostpack(id: number):  Promise<Quote | null>
	{
		try {
			const response = await ApiService.get(`${resource}/${id}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const quoteFactory = new QuoteFactory();
					return quoteFactory.createQuoteByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	async getQuoteByReference(reference: string):  Promise<Quote | null>
	{
		try {
			const response = await ApiService.get(`${resource}/reference/${reference}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const quoteFactory = new QuoteFactory();
					return quoteFactory.createQuoteByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	create(payload: any) {
		return ApiService.post(`${resource}`, payload);
	},
	update(payload: any, id: number) {
		return ApiService.put(`${resource}/${id}`, payload);
	},
	delete(id: number) {
		return ApiService.delete(`${resource}/${id}`)
	},
}

export { QuoteService, ResponseError };