
import {ref} from 'vue';
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import Search from "@/components/Search/Search.vue";
import DefaultSwiper from "@/components/Swiper/DefaultSwiper.vue";
import useCustomStore from "@/composable/custom/useCustomStore";
import {QuoteService} from "@/services/api/quote/quote.service";
import {CheckIcon, LockClosedIcon, InformationCircleIcon} from '@heroicons/vue/solid';

export default {
	name: 'DatabaseOverview',
	components: {Search, DefaultCardLayout, CheckIcon, LockClosedIcon, InformationCircleIcon},
	props: {},
	setup() {
		const {getPageReloaded, setPageReloaded, isMobileDevice} = useCustomStore();
		const quoteList = ref();
		const page = ref('0');

		function searchQuotes(searchValue, forceReload = false) {
			let perPage: number|null = 8;
			if (!isMobileDevice()) {
				perPage = 14;
			}
			QuoteService.getQuoteBySearch(searchValue.value, page.value, perPage).then((quotes) => {
				quoteList.value = quotes;
				// if ( (quotes && quotes.paginationMetaData !== null && quotes.paginationMetaData.to !== null && quotes.paginationMetaData.total !== quoteList.value.length) || forceReload) {
				// 	if (quotes) {
				// 		for (const quote of quoteList.value) {
				// 			if (!quoteList.value.find(o => o.id == quote.id )) {
				// 				quoteList.value.push(quoteList);
				// 			}
				// 		}
				// 		state.boostpackList.paginationMetaData = boostpackList.paginationMetaData;
				// 	}
				// }
				// stopLoading();
			});
		}

		return {
			searchQuotes,
			quoteList
		}
	},
}
